<script>
    import axios from 'axios';


    export default {
        name: 'MatomoVisitsSummary',
        // props: ['params'],
        data () {
            return {
                msg: 'Hello World',
                showModal: false,
                userId: null,
                firstName: '',
                lastName: '',
                report: {},
                query: {},
                foo: {},
                showProfileDiv: false,
                method: 'Live.getLastVisitsDetails',

                status: {
                    loading: false,
                    errors: []
                },
                defaultParams: {
                    date: 'today',
                    period: 'month'
                }
            }
        },
        filters: {

        },
        // watch: {
        //     '$route': 'getReport'
        // },
        methods: {
            onUpdateParams: function(val){
                console.log("params updated");
                console.log(val)
                this.getReport(val);
            },
            getReport: function(params) {
                console.log("⭐⭐ getReport");


                console.log(params);
                params = {...params, method: this.method};

                this.status.errors = [];
                this.status.loading = true;
                this.report = {};

                return axios.get('/actions/matomo-module/default', {
                    params: params
                })
                    .then(response => {
                        console.log('response', response);
                        this.status.loading = false;
                        if (response.data.error) {
                            this.status.errors.push(response.data.error);
                        } else {
                            this.status.errors = [];
                            response.data.data.map(item => {
                                console.log('item', item);
                                item.showDetails = false;
                            })
                            this.report = response.data;
                        }
                    })
                    .catch(e => {
                        console.log('ERROR')
                        console.log('e', e);
                        this.status.errors.push(e.response.data)
                    })
            },
            toggleDetails: function(item) {
                console.log('toggle details');
                item.showDetails = !item.showDetails;

            },
            showProfile: function(item) {
                // this.showProfileDiv = true;
                console.log('item', item);
                item.showProfileDiv = !item.showProfileDiv;
            },
            setUser: function(item) {
                this.userId = item.userId;
                this.firstName = item.userData.firstName;
                this.lastName = item.userData.lastName;
            }
        },
        mounted: function(params) {
            console.log('MatomoLive Mounted');
        }
    }
</script>

<template>
    <div class="matomo-live">



        <MatomoParams v-on:update-params="onUpdateParams($event)"></MatomoParams>


        <div v-if="userId">
            <transition name="modal">
                <div class="modal-mask">
                    <div class="modal-wrapper">
                        <div class="modal-dialog modal-lg" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title">{{firstName}} {{lastName}}</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true" @click="userId = null">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <MatomoUserProfile :user_id=userId></MatomoUserProfile>

                                </div>
                                <!--<div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" @click="userId = null">Close</button>
                                </div>-->
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>



        <div v-if="status.loading" class="card">
            <div class="card-body">
                Loading...
            </div>
        </div>

        <div v-if="status.errors.length" class="alert alert-danger">
            Error fetching data:
            <div v-for="error in status.errors">
                {{error}}
            </div>
        </div>

        <div class="card card-stat mb-4" v-for="item in report.data">
            <div class="card-header">
                <small class="x">
                    {{item.serverDatePrettyFirstAction}}
                    <button class="btn btn-link py-0" style="margin-top: -4px;" v-on:click="setUser(item)">
                        <i class="fas fa-user"></i> View User History
                    </button>
                </small>
                <div class="card-sub-header pb-2">
                    <button v-show="item.showDetails === false" class="btn btn-default btn-sm" v-on:click="toggleDetails(item)">
                        <i  class="fas fa-chevron-down"></i>
                    </button>

                    <button v-show="item.showDetails" class="btn btn-default btn-sm" v-on:click="toggleDetails(item)">
                        <i  class="fas fa-chevron-up"></i>
                    </button>
                    <strong v-if="item.userData">
                        {{item.userData.firstName}} {{item.userData.lastName}}
                    </strong>
                    <strong v-else>
                        <span class="text-muted">[Name Not Found]</span>
                    </strong>
                    <span v-if="item.dimension1"> | {{item.dimension1}} <span v-if="item.dimension2">({{item.dimension2}})</span></span>

                    &nbsp;


                </div>
            </div>
           <div class="card-body" v-if="item.showDetails">
               <table class="table table-sm table-bordered table-striped">
                   <tbody>
                   <tr v-for="action in item.actionDetails">
                       <td>{{action.serverTimePretty}}</td>
                       <td v-if="action.type == 'event'">
                           <strong>{{action.eventCategory}} - {{action.eventAction}}</strong><br>
                           {{action.eventName}}
                       </td>
                       <td v-else >
                           <strong>{{action.title}}</strong><br>
                           <span style="display: inline-block; max-width: 300px; overflow: hidden">{{action.subtitle}}</span>
                       </td>

                       <td>{{action.timeSpentPretty}}</td>
                   </tr>

                   </tbody>
               </table>
           </div>
        </div>
        <!--            <pre>{{report}}</pre>-->
    </div>
</template>

<style>

    .modal-mask {
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: table;
        transition: opacity .3s ease;
        pointer-events: none;
    }

    .modal-content {
        max-height: 90vh;
    }

    .modal-body {
        overflow-y: auto;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
        max-height: 100%;
    }
</style>
