<script>
    import axios from 'axios';


    export default {
        name: 'Matomo',
        // props: ['info'],
        data () {
            return {
                msg: 'Hello World',
                report: {},
                status: {
                    loading: false,
                    errors: []
                },
                defaultParams: {
                    visitsSummary: {
                        method: 'VisitsSummary.foo',
                        date: 'today',
                        period: 'week'
                    },
                    live: {
                        method: 'Live.getLastVisitsDetails',
                        date: 'today',
                        period: 'month'
                    },
                    report: {
                        method: 'CustomReports.getCustomReport',
                        date: 'today',
                        period: 'month'
                    },
                    profile: {
                        method: 'Live.getVisitorProfile',
                        visitorId: 'ef806d3af3d289a4',
                        period: 'month'
                    }
                }

            }
        },
        methods: {
            onEnlargeText: function() {
                console.log("enlarge text@@@")
            },
            getReport: function(params) {
                // return axios.get('/actions/matomo-module/default', {
                //     params: params
                // })
                //     .then(response => response)
                //     .catch(e => {
                //         console.log({e})
                //         console.log(e);
                //         this.status.errors.push(e.response.data)
                //     })
            }
        },
        mounted() {
            // this.getReport({
            //     method: 'VisitsSummary.get',
            //     date: 'today',
            //     period: 'day'
            // }).then(response=>{
            //     this.report = response.data;
            // });

        }
    }
</script>

<template>
    <div class="matomo card">
        <div class="matomo-inner card-body">
            <ul class="nav nav-pills nav-pills-sm mb-4">
                <li class="nav-item">
                    <router-link class="nav-link" to="/overview">Overview</router-link>
                </li>
                <li class="nav-item">
                    <router-link class="nav-link" to="/live">Real Time</router-link>
                </li>
                <li class="nav-item">
                    <router-link class="nav-link" to="/report/">Reports</router-link>
                </li>
                <!--<li class="nav-item">
                    <router-link class="nav-link" to="/params">Params</router-link>
                </li>-->
            </ul>


            <router-view foo="barbaz" v-on:update-params="onEnlargeText"></router-view>

        </div>
    </div>
</template>
