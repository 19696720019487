<script>
    import axios from 'axios';

    export default {
        name: 'MatomoVisitsSummary',
        data () {
            return {
                report: {},
                status: {
                    loading: false,
                    errors: []
                },
                method: 'VisitsSummary.get',
            }
        },
        watch: {
            '$route': 'getReport'
        },
        methods: {
            onUpdateParams: function(val){
                console.log('on update params')
                console.log(val);
                this.getReport(val);
            },
            fetchData: function(){
                console.log('fetch!')
            },
            getReport: function(params) {
                console.log("getReport");

                params = {...params, method: this.method};
                console.log(params);

                this.status.loading = true;

                return axios.get('/actions/matomo-module/default', {
                    params: params
                })
                    .then(response => {
                        this.status.loading = false;
                        this.report = response.data;
                    })
                    .catch(e => {
                        this.status.errors.push(e.response.data)
                    })
            }
        },
        mounted() {

        }
    }
</script>

<template>
    <div class="matomo-visit-summary">

        <MatomoParams v-on:update-params="onUpdateParams($event)"></MatomoParams>



        <div class="card">
            <div class="card-body">
                <div v-if="status.loading">Loading...</div>

                <div v-if="report.data && !status.loading">
                    <dl>
                        <dt>Unique Visitors</dt>
                        <dd>{{report.data.nb_uniq_visitors}}</dd>
                        <dt>Users</dt>
                        <dd>{{report.data.nb_users}}</dd>
                        <dt>Visits</dt>
                        <dd>{{report.data.nb_visits}}</dd>
                        <dt>Actions</dt>
                        <dd>{{report.data.nb_actions}}</dd>
                        <dt>Actions / Visit</dt>
                        <dd>{{report.data.nb_actions_per_visit}}</dd>
                        <dt>Average Time on Site</dt>
                        <dd>{{report.data.avg_time_on_site}}</dd>
                    </dl>

                    <pre>{{report}}</pre>
                </div>
            </div>
        </div>

    </div>
</template>
