<script>
    import axios from 'axios';


    export default {
        name: 'MatomoVisitsSummary',
        props: ['params', 'user_id'],
        data () {
            return {
                msg: 'Hello World',
                visits: null,
                query: {},
                foo: {},
                status: {
                    loading: false,
                    errors: []
                },
                stats: {
                    base: [
                        {
                            label: 'Total Visits',
                            key: 'totalVisits'
                        },
                        {
                            label: 'Total Page Views',
                            key: 'totalPageViews'
                        },
                    ],
                    visit: [
                        {
                            label: 'Visit Duration',
                            key: 'visitDurationPretty'
                        },
                        {
                            label: 'Browser Name',
                            key: 'browserName'
                        },
                    ]
                }
            }
        },
        filters: {

        },
        methods: {
            onUpdateParams: function() {

                console.log('params updated')
            },
            getProfile: function(params) {
                console.log("getProfile");
                console.log(params);
                this.status.loading = true;
                return axios.get('/actions/matomo-module/default', {
                    params: {
                        method: 'Live.getLastVisitsDetails',
                        filter_limit: 10000,
                        // visitorId: this.user_id,
                        segment: "userId==Craft_" + this.user_id
                        // period: 'day'
                    }
                })
                    .then(response => response)
                    .catch(e => {
                        console.log({e})
                        console.log(e);
                        this.status.errors.push(e.response.data)
                    })
            }
        },
        watch: {
            user_id: function() {
                console.log('id changed!!');

            }
        },
        mounted: function(params) {
            console.log('mounted!!')
            this.getProfile(this.params).then(response=>{
                console.log('response.data', response.data);
                this.visits = response.data.data;
                this.status.loading = false;
            });
        }
    }
</script>

<template>

    <div>
        <div class="loading" v-if="status.loading">Loading...</div>

        <div v-if="visits">

            <p class="text-lg">
                <strong>Total Visits:</strong> {{visits.length}}
            </p>


            <div v-for="visit in visits">
                <h4>{{visit.serverDatePretty}}</h4>

                <div class="visit-info">
                    <img class="browser-icon"
                         v-bind:src="'https://platform-b.matomo.cloud/' + visit.browserIcon"
                         v-bind:alt="visit.browserName">
                    <div>{{visit.visitDurationPretty}}</div>


                </div>

                <table class="table table-sm table-bordered table-striped">
                    <tbody>
                    <tr v-for="action in visit.actionDetails">
                        <td>{{action.serverTimePretty}}</td>
                        <td v-if="action.type == 'event'">
                            <span class="badge badge-primary text-uppercase">{{action.eventCategory}}</span> &nbsp;
                            <span class="badge badge-secondary text-uppercase">{{action.eventAction}}</span>
                            <div><strong>{{action.eventName}}</strong></div>

                        </td>
                        <td v-else >
                            <strong>{{action.title}}</strong><br>
                            <span style="display: inline-block; max-width: 300px; overflow: hidden">{{action.subtitle}}</span>
                        </td>

                        <td>{{action.timeSpentPretty}}</td>
                    </tr>

                    </tbody>
                </table>
            </div>
        </div>
    </div>

</template>

<style>
    .browser-icon {
        width: 16px;
        height: auto;
        margin-right: 0.5rem;
    }
    .visit-info {
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;
    }

    .loading {

        text-align: center;
        min-height: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

</style>
