import Vue from 'vue'
import HelloWorld from './components/HelloWorld.vue'
import AssetBuilder from './components/AssetBuilder.vue'
import EditUser from './components/EditUser.vue'
import EditTeam from './components/EditTeam.vue'
import InstantSearch from './components/InstantSearch.vue'
import Matomo from "./components/Matomo";
import MatomoParams from "./components/MatomoParams";
import MatomoVisitsSummary from "./components/MatomoVisitsSummary";
import MatomoLive from "./components/MatomoLive";
import MatomoUserProfile from "./components/MatomoUserProfile";
import MatomoCustomReport from "./components/MatomoCustomReport";
import MatomoEvents from "./components/MatomoEvents";
// Import all components here

Vue.component('HelloWorld', HelloWorld)
Vue.component('Matomo', Matomo)
Vue.component('MatomoParams', MatomoParams)
Vue.component('MatomoVisitsSummary', MatomoVisitsSummary)
Vue.component('MatomoLive', MatomoLive)
Vue.component('MatomoCustomReport', MatomoCustomReport)
Vue.component('MatomoEvents', MatomoEvents)
Vue.component('MatomoUserProfile', MatomoUserProfile)
Vue.component('MatomoCustomReport', MatomoCustomReport)
Vue.component('AssetBuilder', AssetBuilder)
Vue.component('EditUser', EditUser)
Vue.component('EditTeam', EditTeam)
Vue.component('InstantSearch', InstantSearch)
// Attach all components to Vue here
