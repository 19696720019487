<script>
    import axios from 'axios';
    import qs from 'qs';

    import instantsearch from "instantsearch.js";
    import {hits} from "instantsearch.js/es/widgets";
    import {searchBox} from "instantsearch.js/es/widgets";
    import {refinementList} from "instantsearch.js/es/widgets";
    import {stats} from "instantsearch.js/es/widgets";
    import {pagination} from "instantsearch.js/es/widgets";
    import {currentRefinedValues} from "instantsearch.js/es/widgets";
    import {clearAll} from "instantsearch.js/es/widgets";
    import {hitsPerPageSelector} from "instantsearch.js/es/widgets";
    import {menuSelect} from "instantsearch.js/es/widgets";

    export default {
        name: 'EditUser',
        created() {
            const search = instantsearch({
                appId: 'L3KPWQLOSZ',
                apiKey: '8f6a6b3588f50a797c2e11b6c0b4ee32',
                indexName: 'content',
                routing: true,
                searchParameters: {
                    // hierarchicalFacetsRefinements: { // menu is implemented as a hierarchicalFacetsRefinements
                    //   categories: ['Cell Phones']
                    // },
                    facets: ['contentType'],

                    facetsRefinements: {
                        "contentType.handle": algoliaContentTypes
                    },
                    // Add to "facets" all attributes for which you
                    // do NOT have a widget defined
                    // facets: ['contentType.handle']
                },
            });
            search.addWidget(
                hits({
                    container: '#hits',
                    templates: {
                        empty: 'No results',
                        item: document.getElementById('hit-template').innerHTML,

                    }

                })
            );

            search.start();
        },
        data: function () {
            return {
            };
        },
        methods: {

        }
    };
</script>

<template>
    <div>
        <h2>Test:</h2>
        <div id="hits">
          <!-- Hits widget will appear here -->
        </div>
    </div>
</template>