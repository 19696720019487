<script>
    import axios from 'axios';
    import qs from 'qs';
    import _ from 'lodash';

    export default {
        name: 'EditTeam',
        created() {
            this.fetchTeam(true);
            axios.get(`/user/currentUser.json`)
                .then(response => {
                    // JSON responses are automatically parsed.
                    this.currentUser = response.data
                })
                .catch(e => {
                    this.errors.push(e)
                })
        },
        computed: {
            haveNewLogos: function () {
                return _.size(this.team.newLogos);
            }
        },
        data: function () {
            return {
                currentUser: {
                    id: window.vueEntryId
                },
                fileFields: [
                    {
                        label: "Logos",
                        handle: 'logos',
                        label_new: 'New Logos',
                        handle_new: 'newLogos'
                    },
                    // {
                    //     label: "Images",
                    //     handle: 'images',
                    //     label_new: 'New Images',
                    //     handle_new: 'newImages'
                    // }
                ],
                team: {
                    logos: [],
                    newLogos: [],
                    images: [],
                    newImages: [],
                    pendingMembers: [],
                },
                newMember: {
                    email: ""
                },
                status: {},
                errors: [],
                image: "",
                debug: {}
            };
        },
        methods: {

            fetchTeam: function (clearNewImages) {
                if (clearNewImages) {
                    this.clearNewImages();
                    return;
                }
                this.status = {loading: "Fetching Team Information"};
                axios.get('/teams/' + window.vueEntryId + '.json')
                    .then(response => {
                        this.status = {};
                        console.log('response.data.owners', response.data.owners);
                        _.map(response.data.owners, function (user) {
                            user.editing = false;
                            return user;
                        });
                        _.map(response.data.members, function (user) {
                            user.editing = false;
                            return user;
                        });
                        this.team = response.data
                    })
                    .catch(e => {
                        this.errors.push(e)
                    })
            },

            processUserCrud: function (member, role) {
                console.log("adding member:");
                console.log(member);
                this.status = {
                    submitting: true,
                    success: false,
                    errors: false
                };
                let status = this.status;
                let errors = this.errors;
                let data = member;
                if (member.id) {
                    data.userId = member.id;
                }
                data.fields = {};
                console.log('this.team.id', this.team.id);
                if (role === 'owner') {
                    console.log("Making user an owner");
                    // Add Ownership
                    member.ownerOf.push(this.team.id);
                    data.fields.ownerOf = member.ownerOf;
                    // Remove membership
                    let index = member.memberOf.indexOf(this.team.id);
                    if (index > -1) {
                        member.memberOf.splice(index, 1);
                    }
                    if (!member.memberOf.length) {
                        console.log("owner of array is empty");
                        member.memberOf = null;
                    }
                    data.fields.memberOf = member.memberOf;
                    data.fields.ownerOf = member.ownerOf;

                    this.submitUserChanges(data);
                }
                if (role === 'member') {
                    console.log("making user a member");
                    // Add Membership
                    member.memberOf.push(this.team.id);
                    data.fields.memberOf = member.memberOf;
                    // Remove ownership
                    let index = member.ownerOf.indexOf(this.team.id);
                    if (index > -1) {
                        member.ownerOf.splice(index, 1);
                    }
                    if (!member.ownerOf.length) {
                        console.log("owner of array is empty");
                        member.ownerOf = null;
                    }
                    data.fields.ownerOf = member.ownerOf;
                    this.submitUserChanges(data);
                }
                if (role === 'remove') {
                    console.log("removing user from team");
                    member.memberOf = _.uniq(member.memberOf);
                    member.ownerOf = _.uniq(member.ownerOf);

                    // Remove ownership
                    let ownerIndex = member.ownerOf.indexOf(this.team.id);
                    if (ownerIndex > -1) {
                        member.ownerOf.splice(ownerIndex, 1);
                    }
                    if (!member.ownerOf.length) {
                        console.log("owner of array is empty");
                        member.ownerOf = null;
                    }
                    // Remove membership
                    let memberIndex = member.memberOf.indexOf(this.team.id);
                    if (memberIndex > -1) {
                        console.log('is a member');
                        member.memberOf.splice(memberIndex, 1);
                    }
                    console.log(member.memberOf);
                    if (!member.memberOf.length) {
                        console.log("owner of array is empty");
                        member.memberOf = null;
                    }

                    data.fields.ownerOf = member.ownerOf;
                    data.fields.memberOf = member.memberOf;
                    this.submitUserChanges(data);

                }
                if (role === 'newMember') {
                    console.log("ading new member");
                    axios.get('/user/get.json', {
                        params: {
                            email: member.email
                        }
                    })
                        .then(response => {
                            console.log('user exists');
                            // If the user exists, add them to the team
                            member = response.data;
                            if (member.id) {
                                member.userId = member.id;
                            }
                            member.memberOf.push(this.team.id);
                            member.fields = {
                                memberOf: member.memberOf
                            };
                            data = member;
                            console.log('data', data);
                            this.submitUserChanges(data);
                        })
                        .catch(e => {
                            // If the user doesn't exist, create them.
                            console.log("handling error");
                            console.log('e', e);
                            if (e.response.status === 404) {
                                this.submitUserChanges(data);
                            } else {
                                status.errors = response.data.errors;
                            }
                            this.errors.push(e);
                            console.log('e', e);
                            console.log(e.response);
                        });
                    member.memberOf = [this.team.id];
                    data.fields.memberOf = member.memberOf;
                }

                console.log('data', data);


            },

            submitUserChanges: function (data) {
                this.status = {
                    submitting: true,
                    success: false,
                    errors: false
                };

                let status = this.status;
                let errors = this.errors;
                console.log('submitUserChanges');
                data.action = 'users/save-user';

                data.sendVerificationEmail = true;
                data[window.csrfTokenName] = window.csrfTokenValue; // Append CSRF Token
                axios.post('/', qs.stringify(data))
                    .then(response => {
                        console.log(response);
                        status.submitting = false;
                        if (response.data.success) {
                            status.success = response.data.success;
                            this.fetchTeam(false);
                        }
                        if (response.data.errors) {
                            status.errors = response.data.errors;
                        }
                    })
                    .catch(e => {
                        console.error('user save error:');
                        status.submitting = false;
                        status.errors = error.response.data;
                    })
            },

            clearNewImages: function (fileField) {
                console.log("Clearing New Images");
                this.status.loading = "Clearing...";

                let data = {
                    action: 'entries/save-entry',
                    entryId: this.team.id,
                    fields: {}
                };

                if (fileField) {
                    data.fields[fileField] = null;
                } else {
                    _.forEach(this.fileFields, function (myFileField) {
                        data.fields[myFileField.handle_new] = null;
                    });
                    data.entryId = window.vueEntryId;
                }

                console.log('data', data);

                data[window.csrfTokenName] = window.csrfTokenValue; // Append CSRF Token

                axios.post('/', qs.stringify(data))
                    .then(response => {
                        this.status.loading = false;
                        console.log('response', response);
                        this.fetchTeam(false);
                    })
                    .catch(e => {
                        this.errors.push(e)
                    })
            },

            removeFile: function (fileArray, index) {
                fileArray.splice(index, 1);
            },

            submitFile: function (fileField, files) {

                this.status = {uploading: "Uploading Images..."};

                let formData = new FormData();

                /*
                    Add the form data we need to submit
                */
                console.log('this.files', files);

                _.forEach(files, function (value) {
                    console.log(value);
                    formData.append('fields[' + fileField + '][]', value);
                });

                formData.append('action', 'entries/save-entry');
                formData.append('entryId', this.team.id);
                formData.append(window.csrfTokenName, window.csrfTokenValue);

                /*
                  Make the request to the POST /single-file URL
                */
                axios.post('/', formData, {headers: {'Content-Type': 'multipart/form-data'}})
                    .then(response => {
                        // JSON responses are automatically parsed.
                        console.log('response', response);
                        if (response.data.errors) {
                            this.status = {
                                errors: response.data.errors
                            };
                        } else {
                            this.status = {};
                            this.fetchTeam();
                        }
                    })
                    .catch(e => {
                        this.status = {};
                        this.errors.push(e)
                    })
            },

            /*
              Handles a change on the file upload
            */
            handleFileUpload: function (fileField) {
                console.log('handlefileUpload');
                console.log(fileField);
                // this.files = this.$refs['files_' + fileField]['files'];
                console.log('this.$refs');
                console.log(this.$refs);
                console.log("this.$refs['files_' + fileField]");
                console.log(this.$refs['files_' + fileField][0]);
                this.submitFile(fileField, this.$refs['files_' + fileField][0]['files']);
            },

            imagePreview: function ($event) {
                console.log('imagePreview');
                console.log($event);

                var files = $event.target.files || $event.dataTransfer.files;
                console.log(files);
                this.createImage(files[0]);


            },
            createImage(file) {
                var image = new Image();
                var reader = new FileReader();
                var vm = this;

                reader.onload = (e) => {
                    vm.image = e.target.result;
                };
                reader.readAsDataURL(file);
            },
            submitForm: function () {
                console.log('submitting form');


                this.status = {submitting: true};
                let team = this.team;

                let data = {
                    action: 'entries/save-entry',
                    entryId: this.team.id,
                    title: this.team.title,
                    fields: {}
                };

                _.forEach(this.fileFields, function (fileField) {
                    data.fields[fileField.handle] = _.map(team[fileField.handle], 'id');
                    _.forEach(team[fileField.handle_new], function (value) {
                        data.fields[fileField.handle].push(value.id);
                    });
                });

                data[window.csrfTokenName] = window.csrfTokenValue; // Append CSRF Token

                axios.post('/', qs.stringify(data))
                    .then(response => {
                        console.log(response);
                        if (response.data.success) {
                            this.status = {success: "Team Information Loaded!"};
                            this.fetchTeam(true);
                        }
                        if (response.data.errors) {
                            this.errors = response.data.errors;
                        }
                    })
                    .catch(e => {
                        console.error('user save error:');
                        status.submitting = false;
                        status.errors = e.response.data;
                    })


            }
        }
    };
</script>

<template>
    <div>
        <div class="stickyStatus">
            <div class="callout small" v-if="status.loading">
                {{status.loading}}
            </div>
            <div class="callout small success" v-if="status.success">
                {{status.success}}
            </div>
            <div class="callout small alert" v-if="status.errors">
                {{status.errors}}
            </div>
        </div>
        <h1>{{team.title}}</h1>

        <form method="post" action="" accept-charset="UTF-8" @submit.prevent="submitForm">

            <div class="row">
                <div class="col-md-8">
                    <div class="grid-container full">
                        <div v-for="(fileField, index) in fileFields">
                            <div class="card mb-4">
                                <div class="card-header">{{fileField.label}}</div>
                                <div class="card-body">

                                    <div class="row mb-4">
                                        <div class=" col-sm-3" v-for="(file, index) in team[fileField.handle]">

                                            <div class="thumbnail">
                                                <!-- TODO: replace ✖ with icon -->
                                                <button class="btn btn-light btn-sm delete-button"
                                                        v-on:click.prevent="removeFile(team[fileField.handle], index)">
                                                    ✖
                                                </button>
                                                <img v-bind:src="file.url" v-bind:alt="file.title"
                                                     class="img-fluid">
                                            </div>

                                        </div>
                                    </div>

                                    <div class="alert alert-info" v-if="team[fileField.handle_new].length">
                                        The files below will be added to your team when you save your changes.
                                    </div>
                                    <div class="callout small" v-if="status.uploading">
                                        <p>{{status.uploading}}</p>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-3" v-for="newFile in team[fileField.handle_new]">
                                            <div class="thumbnail" style="position: relative">
                                                <img v-bind:src="newFile.url" v-bind:alt="newFile.title"
                                                     class="img-fluid">
                                            </div>
                                        </div>
                                    </div>

                                    <!--<input type="file" id="file" ref="file" v-on:change="handleFileUpload()" multiple />-->


                                    <div>
                                        <label v-bind:for="'upload_' + fileField.handle_new" class="button">Upload
                                            New
                                            {{fileField.label}}</label>
                                        <input type="file" v-bind:id="'upload_' + fileField.handle_new"
                                               v-bind:ref="'files_' + fileField.handle_new" class="show-for-sr"
                                               v-on:change="handleFileUpload(fileField.handle_new)" multiple>
                                        <button class="btn btn-warning"
                                                v-on:click="clearNewImages(fileField.handle_new)"
                                                v-if="team[fileField.handle_new].length">Clear
                                        </button>
                                    </div>


                                    <div class="card" v-if="false">
                                        <div class="card-divider">Upload Preview Test</div>
                                        <div class="card-section">
                                            <input class="form-control form-control-file" type="file" name="images[]"
                                                   id="image-upload"
                                                   @change="imagePreview($event)"
                                                   multiple>
                                            <img :src="image"/>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>

                    </div>

                </div>
                <div class="col-md-4">
                    <div class="card mb-4">
                        <div class="card-header">Team Info</div>
                        <div class="card-body">
                            <div class="form-group">
                                <label for="firstName">Team Name</label>
                                <input id="firstName" class="form-control" type="text" name="firstName" value=""
                                       v-model="team.title">
                            </div>
                        </div>
                    </div>
                    <div class="card mb-4">
                        <div class="card-header">Team Owner(s)</div>

                        <ul class="member-list list-group list-group-flush">
                            <li class="list-group-item member-list-item" v-for="user in team.owners" :key="user.id">
                                <div v-if="(user.firstName || user.lastName)">
                                    {{user.firstName}} {{user.lastName}}
                                </div>
                                {{user.email}} ({{user.id}})
                                <button class="btn btn-link edit"
                                        v-on:click.prevent="user.editing = !user.editing">Edit
                                </button>
                                <div class="member-edit" v-if="user.editing">
                                    <div class="button-group tiny">
                                        <button class="btn btn-sm btn-secondary">Remove from Team</button>
                                        <button class="btn btn-sm btn-secondary tiny"
                                                v-on:click.prevent="processUserCrud(user, 'member')">Remove
                                            Ownership
                                        </button>
                                    </div>
                                </div>
                            </li>
                        </ul>


                    </div>
                    <div class="card mb-4">
                        <div class="card-header">Team Member(s)</div>

                        <ul class="member-list list-group list-group-flush">
                            <li class="member-list-item list-group-item" v-for="user in team.members" :key="user.id">
                                <div v-if="(user.firstName || user.lastName)">
                                    {{user.firstName}} {{user.lastName}}
                                </div>
                                {{user.email}} ({{user.id}})
                                <button class="btn btn-link edit"
                                        v-on:click.prevent="user.editing = !user.editing">Edit
                                </button>
                                <div class="member-edit" v-if="user.editing">
                                    <div class="button-group tiny">
                                        <button class="btn btn-secondary btn-sm">Remove from Team</button>
                                        <button class="btn btn-secondary btn-sm"
                                                v-on:click.prevent="processUserCrud(user, 'owner')">Make Owner
                                        </button>
                                    </div>
                                </div>
                            </li>
                            <li class="member-list-item list-group-item">
                                <div class="form-group">
                                    <label>Add a Member</label>
                                    <input class="form-control" type="email" placeholder="Email Address"
                                           v-model="newMember.email">
                                </div>

                                <button class="btn btn-primary"
                                        v-on:click.prevent="processUserCrud(newMember, 'newMember')">Add
                                </button>
                            </li>
                        </ul>

                        <div v-if="team.pendingMembers.length">
                            <div class="card-divider">Pending Members(s)</div>
                            <div class="card-section">
                                <div class="member-list">
                                    <div class="member-list-item" v-for="user in team.pendingMembers" :key="user.id">
                                        {{user.email}}
                                        <a v-on:click.prevent="processUserCrud(user, 'remove')">
                                            <icon name="ban"></icon>
                                        </a>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button type="submit" class="btn btn-primary btn-block">Save Team</button>
                    <span v-if="status.submitting">Submitting...</span>
                    <span v-if="status.success">Success!</span>
                </div>
            </div>
        </form>

        <!--<pre>{{currentUser}}</pre>-->
        <!--<pre>{{team}}</pre>-->
    </div>
</template>