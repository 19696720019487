<template>
    <div class="well">
        <MatomoParams v-on:update-params="onUpdateParams($event)"></MatomoParams>

        <div class="card card-stat mb-4">
            <div class="card-body ">
                <div class="">
                    <ul class="nav nav-pills nav-pills-sm justify-content-end">
                        <li class="nav-item">
                            <a class="nav-link"
                               v-on:click="updateChartPeriod('day')"
                               :class="{active: chartOptions.period === 'day'}" href="#">
                                Day
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link"
                               v-on:click="updateChartPeriod('week')"
                               :class="{active: chartOptions.period === 'week'}" href="#">
                                Week
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link"
                               v-on:click="updateChartPeriod('month')"
                               :class="{active: chartOptions.period === 'month'}" href="#">
                                Month
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="chart-line">
                    <div v-if="status.loadingChart">Loading...</div>
                    <line-chart v-if="datacollection" :options="chartOptions" :chart-data="datacollection"></line-chart>

                </div>
            </div>
        </div>
        <div class="summary">
            <div v-if="status.loading">
                <div class="row row-cols-4">
                    <div class="col">
                        <div class="card card-sm card-stat">
                            <div class="card-body">
                                <div class="card-stat-label">Visits</div>
                                ...
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="card card-sm card-stat">
                            <div class="card-body">
                                <div class="card-stat-label">Actions</div>
                                ...
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="card card-sm card-stat">
                            <div class="card-body">
                                <div class="card-stat-label">Actions per Visit</div>
                                ...
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="card card-sm card-stat">
                            <div class="card-body">
                                <div class="card-stat-label">Average Time on Site</div>
                                ...
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="summaryReport.data && !status.loading">
                <div class="row row-cols-4">
                    <div class="col">
                        <div class="card card-sm card-stat card-stat-border-orange">
                            <div class="card-body">
                                <div class="card-stat-label">Visits</div>
                                <div class="card-stat-value">{{summaryReport.data.nb_visits}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="card card-sm card-stat card-stat-border-orange">
                            <div class="card-body">
                                <div class="card-stat-label">Actions</div>
                                <div class="card-stat-value">{{summaryReport.data.nb_actions}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="card card-sm card-stat card-stat-border-orange">
                            <div class="card-body">
                                <div class="card-stat-label">Actions per Visit</div>
                                <div class="card-stat-value">{{summaryReport.data.nb_actions_per_visit}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="card card-sm card-stat card-stat-border-orange">
                            <div class="card-body">
                                <div class="card-stat-label">Average Time on Site</div>
                                <div class="card-stat-value">
                                    {{summaryReport.data.avg_time_on_site}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
</template>

<style>
    .chart-line {
        height: 440px;
    }
</style>

<script>
    import LineChart from './LineChart.js'
    import axios from 'axios';

    export default {
        components: {
            LineChart
        },
        data () {
            return {
                datacollection: null,
                status: {
                    loading: false,
                    loadingChart: false,
                    errors: []
                },
                summaryReport: {},
                chartOptions: {
                    maintainAspectRatio: false,
                    aspectRatio: 2,
                    responsive: true,
                    period: 'day',
                    elements: {
                        line: {
                            tension: 0 // disables bezier curves
                        }
                    },
                    scales: {
                        xAxes: [{
                            // type: 'time',
                            ticks: {
                                source: 'data',
                                autoSkip: true,
                                autoSkipPadding: 15,
                                maxRotation: 30,
                                sampleSize: 400
                            },
                            // time: {
                            //     tooltipFormat: 'ddd M.D.YY'
                            // },
                        }],
                        // yAxes: [
                        //     {
                        //         ticks: {
                        //             beginAtZero: true,
                        //             maxTicksLimit: 4,
                        //         },
                        //     },
                        // ],
                    },
                },
                params: {
                    // date: '2020-08-17,2020-12-13',
                    // module: 'API',
                    // period: 'day',
                    // method: 'API.getProcessedReport',
                    // date: '2020-08-17,2020-09-15',
                    // apiAction: 'get',
                    // apiModule: 'API',
                    // module: 'API',
                    // period: 'day',
                }

            }
        },
        mounted () {
            // this.fillData()
        },
        methods: {
            onUpdateParams: function(val){
                console.log("params updated");
                console.log(val)
                this.params = val;
                this.getChartReport(val);
                this.getSummaryReport(val);
                // this.fillData();
            },
            updateChartPeriod: function(period) {
                this.chartOptions.period = period;
                this.getChartReport();
            },
            getSummaryReport: function(params) {
                console.log("⭐⭐⭐⭐ getReport");

                params = {...params, method: 'VisitsSummary.get'};
                console.log(params);

                this.status.loading = true;

                return axios.get('/actions/matomo-module/default', {
                    params: params
                })
                    .then(response => {
                        this.status.loading = false;
                        this.summaryReport = response.data;
                    })
                    .catch(e => {
                        this.status.errors.push(e.response.data)
                    })
            },
            getChartReport: function(params) {
                console.log("⭐⭐ getChartReport");

                params = {
                    ...this.params,
                    period: this.chartOptions.period,
                    method: 'API.get',
                };

                console.log(params);

                this.status.errors = [];
                this.status.loadingChart = true;
                this.report = {};
                this.datacollection = null;

                return axios.get('/actions/matomo-module/default', {
                    params: params
                })
                    .then(response => {
                        console.log('⭐️ response', response);
                        this.status.loadingChart = false;

                        if (response.data.error) {
                            this.status.errors.push(response.data.error);
                        } else {
                            this.status.errors = [];
                            console.log('response.data.data', response.data);
                            // Object.keys(response.data.data).map(item => {
                            //     console.log('item', item);
                            //     console.log('response.data.data[item]', response.data.data[item]);
                            // })
                            this.report = response.data.data;
                            this.fillData(this.report)
                        }
                    })
                    .catch(e => {
                        console.log('🚨 ERROR 🚨');
                        console.log('e', e);
                        // this.status.errors.push(e.response.data)
                    })
            },
            buildData (labels, report) {
                console.log('bildData');
                let datasets = [];
                if (report.reportData) {
                    report = report.reportData;
                }
                console.log('report', report);
                let values = Object.values(report);

                function pluck(array, key) {
                    return array.map(o => o[key]);
                }

                let colors = [
                    "#003f5c",
                    "#58508d",
                    "#bc5090",
                    "#ff6361",
                    "#ffa600",
                ];

                let i = 0;

                labels.forEach(label => {
                    console.log('label', label);
                    let data;
                    data = pluck(values, label.key);
                    data = data.map(item => {
                        return item || 0;
                    });

                    datasets.push({
                        label: label.value,
                        // backgroundColor: 'deepskyblue',
                        borderColor: colors[i],
                        data
                    })
                    i++;
                });

                console.log('datasets', datasets);

                return datasets;
            },
            fillData (report) {
                console.log('fillData');
                console.log('report', report);
                if (!report) {
                    return;
                }

                let labels = [
                    {
                        key: 'nb_visits',
                        value: 'Visits'
                    },
                    {
                        key: 'nb_pageviews',
                        value: 'Pageviews'
                    },
                ];

                let datasets = this.buildData(labels, report);
                this.datacollection = {
                    labels: Object.keys(report),
                    datasets
                }
            },
            getRandomInt () {
                return 20
            }
        },
        mounted() {
            // this.getData();
        }
    }
</script>


