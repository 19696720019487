<script>
    import axios from 'axios';
    import LineChart from './LineChart.js'

    export default {
        name: 'MatomoVisitsSummary',
        props: ['params'],
        components: {
            LineChart
        },
        data () {
            return {
                msg: 'Hello World',
                report: {},
                status: {
                    loading: false,
                    errors: []
                },
                query: {},
                foo: {},
                showProfileDiv: false,
                datacollection: null,
                method: 'API.getProcessedReport',
                defaultParams: {
                    date: 'today',
                    period: 'month'
                },
                chartOptions: {
                    height: 300,
                    responsive: true,
                    maintainAspectRatio: false
                },
                height: 100
            }
        },
        filters: {

        },

        methods: {
            onUpdateParams: function(val){

                console.log('custom report params updated');
                console.log(val);
                this.getReport(val);
            },
            fillData () {
                this.datacollection = {
                    labels: [this.getRandomInt(), this.getRandomInt(), this.getRandomInt(), this.getRandomInt()],
                    datasets: [
                        {
                            label: 'Data One',
                            backgroundColor: 'blue',
                            fill: false,
                            data: [this.getRandomInt(), this.getRandomInt(), this.getRandomInt(), this.getRandomInt()]
                        }, {
                            label: 'Data Two',
                            backgroundColor: 'red',
                            data: [this.getRandomInt(), this.getRandomInt(), this.getRandomInt(), this.getRandomInt()]
                        }
                    ]
                }
            },
            getRandomInt () {
                return Math.floor(Math.random() * (50 - 5 + 1)) + 5
            },
            getReport: function(params) {
                console.log("getReport");
                console.log(params);

                this.status.loading = true;
                this.report = {};

                console.log('this.method', this.method);

                let oldParams = {
                    // method: 'CustomReports.getCustomReport',
                    // date: 'today',
                    // period: 'year',
                    // idCustomReport: 3,
                    // expanded: 1,

                    'apiAction': 'getCustomReport',
                    'apiModule': 'CustomReports',
                    'date': '2019-12-04',
                    'expanded': '1',
                    'filter_limit': '-1',
                    'format': 'JSON',
                    'idCustomReport': '7',
                    'idSite': '2',
                    'method': 'API.getProcessedReport',
                    'module': 'API',
                    'period': 'month',
                    'reportUniqueId': 'CustomReports_getCustomReport_idCustomReport--3',
                    'segment': '',
                    'token_auth': '3065a4593d6670aef6fcc818b8ca6df0'
                }

                // params = {...params,
                //     method: this.method,
                //     idCustomReport: 7,
                //     apiModule: 'CustomReports',
                //     apiAction: 'getCustomReport'
                // };

                params = {...params,
                    // method: this.method,
                    method: 'Events.getName',
                    module: 'API',
                    // idCustomReport: 7,
                    expanded: 1,
                    apiModule: 'CustomReports',
                    apiAction: 'getCustomReport'
                };
                console.log('params', params);

                return axios.get('/actions/matomo-module/default', {
                    params
                })
                    .then(response => {
                        console.log('response', response);
                        this.status.loading = false;
                        if (response.data.error) {
                            this.status.errors.push(response.data.error);
                        } else {
                            this.status.errors = [];
                            this.report = response.data;
                        }
                    })
                    .catch(e => {
                        console.log({e})
                        console.log(e);
                        this.status.errors.push(e.response)
                    })
            },
            showProfile: function(item) {
                // this.showProfileDiv = true;
                console.log('item', item);
                item.showProfileDiv = !item.showProfileDiv;
            }
        },
        mounted: function(params) {
            // this.fillData();
            // console.log('this.datacollection', this.datacollection);
            // this.getReport(this.params).then(response=>{
            //     console.log('response.data', response.data.visitorId);
            //     this.report = response.data.data;
            // });
        },
        computed: {
            myStyles () {
                return {
                    height: `${this.height}px`,
                    position: 'relative'
                }
            }
        }
    }
</script>

<template>


    <div class="card">

        <MatomoParams v-on:update-params="onUpdateParams($event)"></MatomoParams>

        <div class="card-body">


            <table class="table table-sm">
                <thead>
                <tr>
                    <th>&nbsp;</th>
                    <th>Visits</th>
                    <th>Events</th>
                </tr>
                </thead>
                <template v-for="row in report.data">

                    <tbody>
                    <tr>
                        <td>{{row.label}}</td>
                        <td>{{row.nb_visits}}</td>
                        <td>{{row.nb_events}}</td>
                    </tr>
                    </tbody>

                    <tbody class="subtable">
                    <tr v-for="subrow in row.subtable">
                        <td>{{subrow.label}}</td>
                        <td>{{subrow.nb_visits}}</td>
                        <td>{{subrow.nb_events}}</td>
                    </tr>
                    </tbody>
                </template>

            </table>
            <div v-for="row in report.data">

                <pre>{{row}}</pre>
            </div>
<!--            <table class="table table-sm">-->
<!--                -->
<!--            </table>-->
<!--            <div xstyle="height: 300px; position:relative;">-->
<!--                <line-chart :chartData="datacollection" :options="chartOptions" />-->
<!--            </div>-->
<!--            <button @click="fillData()">Randomize</button>-->
<!--            <pre>{{datacollection}}</pre>-->
<!--            <hr>-->
<!--w-->
<!--          <pre>{{report}}</pre>-->
<!--            <pre>{{report}}</pre>-->
        </div>
    </div>
</template>
