<script>
    import axios from 'axios';
    import qs from 'qs';

    export default {
        name: 'EditUser',
        props: ['info'],
        created() {
            axios.get(`/user/currentUser.json`)
                .then(response => {
                    // JSON responses are automatically parsed.
                    this.user = response.data;
                    this.user.userId = this.user.id;
                    this.user.originalEmail = this.user.email;

                })
                .catch(e => {
                    this.errors.push(e)
                })
        },
        data: function () {
            return {
                user: {},
                status: {},
                errors: []
            };
        },
        methods: {
            submitForm: function () {

                this.status = {
                    submitting: true,
                    success: false,
                    errors: false
                };


                let data = this.user;
                let status = this.status;
                let errors = this.errors;
                data.action = 'users/save-user';
                data[window.csrfTokenName] = window.csrfTokenValue; // Append CSRF Token

                axios.post('/', qs.stringify(data))
                    .then(function (response) {
                        console.log(response);
                        status.submitting = false;
                        if (response.data.success) {
                            status.success = response.data.success;
                        }
                        if (response.data.errors) {
                            status.errors = response.data.errors;
                        }

                    })
                    .catch(function (error) {
                        console.error('user save error:');
                        status.submitting = false;
                        status.errors = error.response.data;
                    });

            }
        }
    };
</script>

<template>
    <div class="card">
        <div class="card-body">
            <h1>Your Profile</h1>
            <form method="post" action="" accept-charset="UTF-8" @submit.prevent="submitForm">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="firstName">First Name</label>
                            <input id="firstName" class="form-control" type="text" name="firstName" value=""
                                   v-model="user.firstName">
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="lastName">Last Name</label>
                            <input id="lastName" class="form-control" type="text" name="lastName" value=""
                                   v-model="user.lastName">
                        </div>
                    </div>

                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="email">Email</label>
                            <input id="email" class="form-control" type="text" name="email" value=""
                                   v-model="user.email">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group" v-if="user.email !== user.originalEmail">
                            <label for="password">Password</label>
                            <input id="password" class="form-control" type="password" name="password"
                                   v-model="user.password">
                        </div>
                    </div>
                </div>

            <button type="submit" class="btn btn-primary">Save</button>
                <span v-if="status.submitting">Submitting...</span>
                <span v-if="status.success">Success!</span>
                <div class="callout alert" v-if="status.errors">
                    <pre>{{status.errors}}</pre>
                </div>

                <!--<pre>{{user}}</pre>-->

            </form>

            <h3 class="mt-4">Your Team</h3>
            <div v-if="info.teams.ownerOf.length">
                <ul class="list-unstyled">
                    <li v-for="team in info.teams.ownerOf">You are an owner of the <strong>{{team.title}}</strong> team.
                        <a class="" v-bind:href="'/' + team.uri">Edit Team Settings</a></li>
                </ul>
            </div>
            <div v-if="info.teams.memberOf.length">
                <ul class="list-unstyled">
                    <li v-for="team in info.teams.memberOf">You are an member of the <strong>{{team.title}}</strong> team.</li>
                </ul>
            </div>

        </div>
    </div>
</template>