<script>
    import Datepicker from 'vuejs-datepicker';

    export default {
        name: 'MatomoParams',
        props: ['params'],

        data () {
            var startDate = new Date();
            startDate.setDate(startDate.getDate()-5);
            return {
                msg: 'Hello World',
                report: {},
                query: {},
                date: startDate,
                endDate: new Date(),
                period: 'range',
                status: {
                    loading: false,
                    errors: []
                },
                method: 'VisitsSummary.get',
                defaultParams: {
                }
            }
        },
        components: {
            Datepicker
        },
        watch: {
            '$route': 'getReport'
        },
        methods: {
            fetchData: function(){
                console.log('fetch!')
            },
            parseDate: function(date) {
                return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
            },
            parseParams: function() {
                let {date, endDate, method, period} = this;

                let parsedDate = this.parseDate(date);
                if (period === 'range') {
                    parsedDate += ',' + this.parseDate(endDate);
                }
                console.log('parsedDate', parsedDate);

                console.log('method', method);

                let params = {
                    method,
                    date: parsedDate,
                    period
                };
                // params = {...params, ...this.$route.query};
                console.log('params', params);
                this.$emit('update-params', params);
            }
        },
        mounted() {
            this.parseParams();

        }
    }
</script>

<template>
    <div class="matomo-params mb-4">
        <div class="row">
            <!--<div class="col-md-4">
                <div class="form-group">
                    <label for="matomo-period">Period</label>
                    <select class="form-control" v-model="period" id="matomo-period">
                        <option value="day">Day</option>
                        <option value="week">Week</option>
                        <option value="month">Month</option>
                        <option value="range">Range</option>
                    </select>
                </div>
            </div>-->
            <div class="col-md-4">
                <div class="form-group">
                    <label for="matomo-date">Date</label>
                    <datepicker id="matomo-date" input-class="form-control form-control-sm"  name="startDate" v-model="date"></datepicker>
                </div>
            </div>
            <div class="col-md-4" v-if="period === 'range'">
                <div class="form-group">
                    <label for="matomo-date-end">End Date</label>
                    <datepicker id="matomo-date-end" input-class="form-control form-control-sm"  name="startDate" v-model="endDate"></datepicker>
                </div>
            </div>
            <div class="col-md-4 d-flex align-items-end">
                <div class="form-group">
                    <button v-on:click="parseParams" class="btn btn-primary btn-sm btn-block">Apply</button>
                </div>

            </div>

        </div>
        <div class="row">
            <div class="col">
            </div>
        </div>
    </div>
</template>
